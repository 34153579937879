import { LAST_ACCESS_AIMMO_CORE_WORKSPACE } from '@aimmo/constants/ngforage-key';
import { RxNgForage } from '@aimmo/services/ngforage';
import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Role, Workspace, WorkspaceDetail } from 'aimmo-core2/app/shared/models/workspace.model';
import { catchError, Observable, of, Subject } from 'rxjs';

export interface WorkspaceResolveEvent {
  workspace: Workspace;
  afterResolved: () => Observable<boolean>;
}
@Injectable({
  providedIn: 'root'
})
export class WorkspaceStateService {
  public readonly workspace = signal<Workspace>(null);
  public readonly workspaceRole = signal<Role>(null);
  public readonly workspace$ = toObservable<Workspace>(this.workspace);
  public readonly workspaceDetail = signal<WorkspaceDetail>(null);

  public readonly workspaceResolveEventSource = new Subject<WorkspaceResolveEvent>();

  constructor(
    private ngForage: RxNgForage
  ) {
  }

  public get workspaceResolveEvent$(): Observable<WorkspaceResolveEvent> {
    return this.workspaceResolveEventSource.asObservable();
  }

  public setWorkspaceWithResolve(workspace: Workspace, afterResolved?: () => Observable<boolean>): void {
    this.workspace.set(workspace);
    this.workspaceResolveEventSource.next({
      workspace,
      afterResolved: afterResolved || (() => of(true))
    });
  }

  public getLastAccessedWorkspaceId(userId: string): Observable<string> {
    return this.ngForage.getItem<string>(LAST_ACCESS_AIMMO_CORE_WORKSPACE(userId)).pipe(
      catchError(() => of(undefined))
    );
  }

  public saveLastAccessedWorkspaceId(userId: string, workspaceId: string): Observable<string> {
    return this.ngForage.setItem<string>(LAST_ACCESS_AIMMO_CORE_WORKSPACE(userId), workspaceId).pipe(
      catchError(() => of(workspaceId))
    );
  }
}

